/**
 * cognitoを利用したログイン・ログアウト、セッション管理などを行うためのStoreである。
 */

import {
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { defineStore } from 'pinia';

type Credentials = {
  id: string;
  password: string;
};

export const useCognitoStore = defineStore('cognito', {
  actions: {
    /**
     * ID・パスワードでログインする。
     * @param param ログインパラメータ
     */
    async login(param: Credentials): Promise<void> {
      await Auth.signIn(param.id, param.password);
    },
    /**
     * ログイン中のユーザーを取得する。
     * @returns ユーザーデータ
     */
    async getUser(): Promise<Nullable<CognitoUser>> {
      try {
        const user = await Auth.currentAuthenticatedUser();
        return user;
      } catch (e) {
        return null;
      }
    },
    async logout(): Promise<void> {
      await Auth.signOut();
    },
    /**
     * 現在ログイン中のユーザーのIDトークンを取得する。
     * @returns IDトークン
     */
    async getIdToken(): Promise<string> {
      const res = await Auth.currentSession();
      const token = res.getIdToken().getJwtToken();
      return token;
    },
    /**
     * 現在のセッション情報を取得する.
     * amazon-cognito-identity-js標準のAPIとは異なり、自動的にセッションの更新をしない。
     * @returns セッション情報
     */
    getCurrentSession(): Nullable<CognitoUserSession> {
      const runtimeConfig = useRuntimeConfig();

      const keyPrefix = `CognitoIdentityServiceProvider.${runtimeConfig.public.CLIENT_ID}`;
      const username = sessionStorage.getItem(`${keyPrefix}.LastAuthUser`);

      const idToken = sessionStorage.getItem(
        `${keyPrefix}.${username}.idToken`,
      );
      const accessToken = sessionStorage.getItem(
        `${keyPrefix}.${username}.accessToken`,
      );
      const refreshToken = sessionStorage.getItem(
        `${keyPrefix}.${username}.refreshToken`,
      );
      const clockDrift = sessionStorage.getItem(
        `${keyPrefix}.${username}.clockDrift`,
      );

      if (!idToken || !accessToken || !refreshToken || !clockDrift) {
        return null;
      }

      const sessionData = {
        IdToken: new CognitoIdToken({
          IdToken: idToken,
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: accessToken,
        }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: refreshToken,
        }),
        ClockDrift: parseInt(clockDrift, 0) || 0,
      };

      return new CognitoUserSession(sessionData);
    },
    /**
     * セッションを更新する。
     * セッションがタイムアウトしていた場合には例外をスローする。
     * @returns 更新後のセッション情報
     */
    async refreshSession(): Promise<CognitoUserSession> {
      const currentSession = this.getCurrentSession();
      const user = await Auth.currentAuthenticatedUser();
      if (!currentSession || !currentSession.isValid() || !user) {
        throw new Error('No current user');
      }

      const refreshToken = currentSession.getRefreshToken();
      const res = await new Promise<CognitoUserSession>((resolve, reject) => {
        user.refreshSession(
          refreshToken,
          (err: Error, result: CognitoUserSession) => {
            console.log({ err, result });
            if (err) {
              return reject(err);
            }
            return resolve(result);
          },
        );
      });
      return res;
    },
  },
});
