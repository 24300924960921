import { default as CoeTJh5V840J8Meta } from "/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/Coe.vue?macro=true";
import { default as EnvironmentalSoundJ4vOQvtlDGMeta } from "/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/EnvironmentalSound.vue?macro=true";
import { default as Points8TIvP3FcJlMeta } from "/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/Points.vue?macro=true";
import { default as RecordTestza1vGNaACxMeta } from "/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/RecordTest.vue?macro=true";
import { default as TextQ7k7WaZMy0Meta } from "/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/Text.vue?macro=true";
import { default as FaqSYQEaizxeZMeta } from "/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Faq.vue?macro=true";
import { default as SigninSOaQsKqiCcMeta } from "/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Signin.vue?macro=true";
import { default as indexEPnfECcHzeMeta } from "/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/index.vue?macro=true";
export default [
  {
    name: CoeTJh5V840J8Meta?.name ?? "Create-Coe",
    path: CoeTJh5V840J8Meta?.path ?? "/Create/Coe",
    meta: CoeTJh5V840J8Meta || {},
    alias: CoeTJh5V840J8Meta?.alias || [],
    redirect: CoeTJh5V840J8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/Coe.vue").then(m => m.default || m)
  },
  {
    name: EnvironmentalSoundJ4vOQvtlDGMeta?.name ?? "Create-EnvironmentalSound",
    path: EnvironmentalSoundJ4vOQvtlDGMeta?.path ?? "/Create/EnvironmentalSound",
    meta: EnvironmentalSoundJ4vOQvtlDGMeta || {},
    alias: EnvironmentalSoundJ4vOQvtlDGMeta?.alias || [],
    redirect: EnvironmentalSoundJ4vOQvtlDGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/EnvironmentalSound.vue").then(m => m.default || m)
  },
  {
    name: Points8TIvP3FcJlMeta?.name ?? "Create-Points",
    path: Points8TIvP3FcJlMeta?.path ?? "/Create/Points",
    meta: Points8TIvP3FcJlMeta || {},
    alias: Points8TIvP3FcJlMeta?.alias || [],
    redirect: Points8TIvP3FcJlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/Points.vue").then(m => m.default || m)
  },
  {
    name: RecordTestza1vGNaACxMeta?.name ?? "Create-RecordTest",
    path: RecordTestza1vGNaACxMeta?.path ?? "/Create/RecordTest",
    meta: RecordTestza1vGNaACxMeta || {},
    alias: RecordTestza1vGNaACxMeta?.alias || [],
    redirect: RecordTestza1vGNaACxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/RecordTest.vue").then(m => m.default || m)
  },
  {
    name: TextQ7k7WaZMy0Meta?.name ?? "Create-Text",
    path: TextQ7k7WaZMy0Meta?.path ?? "/Create/Text",
    meta: TextQ7k7WaZMy0Meta || {},
    alias: TextQ7k7WaZMy0Meta?.alias || [],
    redirect: TextQ7k7WaZMy0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Create/Text.vue").then(m => m.default || m)
  },
  {
    name: FaqSYQEaizxeZMeta?.name ?? "Faq",
    path: FaqSYQEaizxeZMeta?.path ?? "/Faq",
    meta: FaqSYQEaizxeZMeta || {},
    alias: FaqSYQEaizxeZMeta?.alias || [],
    redirect: FaqSYQEaizxeZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Faq.vue").then(m => m.default || m)
  },
  {
    name: SigninSOaQsKqiCcMeta?.name ?? "Signin",
    path: SigninSOaQsKqiCcMeta?.path ?? "/Signin",
    meta: SigninSOaQsKqiCcMeta || {},
    alias: SigninSOaQsKqiCcMeta?.alias || [],
    redirect: SigninSOaQsKqiCcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/Signin.vue").then(m => m.default || m)
  },
  {
    name: indexEPnfECcHzeMeta?.name ?? "index",
    path: indexEPnfECcHzeMeta?.path ?? "/",
    meta: indexEPnfECcHzeMeta || {},
    alias: indexEPnfECcHzeMeta?.alias || [],
    redirect: indexEPnfECcHzeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1341844204/src/toppan-voice-recorder-frontend/pages/index.vue").then(m => m.default || m)
  }
]