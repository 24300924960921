import { isAuthError } from '@/lib/error-utils';
import { useDialogStore } from '@/store/dialog';
import { useUserData } from '@/store/userdata';
import { useSpinnerStore } from '@/store/spinner';

/**
 * ログイン中かどうかに応じてルーティングを制御する
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('middleware', to, from);
  const dialog = useDialogStore();
  const userdata = useUserData();
  const spinner = useSpinnerStore();

  // ブラウザの戻る／進むで遷移した場合の対策: 確認ダイアログが残り続けないようにする
  dialog.forceHideDialog();
  // publicページは無条件でOK
  if (to.meta.public) {
    return;
  }
  try {
    spinner.setVisible(true);
    try {
      await userdata.updateUserData({
        section: 'Userinfo',
        name: 'LastLogginedAt',
        value: new Date().toISOString(),
      });
    } finally {
      spinner.setVisible(false);
    }
  } catch (err) {
    // セッション切れの場合はログインページにリダイレクト
    if (isAuthError(err)) {
      return navigateTo('/signin');
    }
    console.error(err);
    showError({
      statusCode: 500,
      statusMessage: 'Internal Server Error',
      message: 'エラー発生',
    });
  }
});
