import { useCognitoStore } from '@/store/cognito';
import { isError, NOT_LOGIN } from '@/lib/error-utils';

type API_PARAMS = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: Record<string, unknown> | Blob;
  headers?: Record<string, string>;
};

/**
 *
 * @param url API接続先のURLを指定する引数
 * @param params API実行パラメータを指定する引数
 * @returns APIからのレスポンス
 */
export default async function api(
  url: string,
  params: API_PARAMS,
): Promise<undefined | Record<string, unknown>> {
  console.log(url, params.method, params.body, params.headers);
  const cognito = useCognitoStore();
  const runtimeConfig = useRuntimeConfig();
  try {
    await cognito.refreshSession();
    const token = await cognito.getIdToken();
    return $fetch(url, {
      baseURL: `${runtimeConfig.public.API_END_POINT}`,
      method: params.method,
      headers: {
        Authorization: token,
        ...params.headers,
      },
      body: params.body ? params.body : undefined,
    });
  } catch (e) {
    console.error(e);
    if (
      (isError(e) && e.message === 'No current user') ||
      e === 'The user is not authenticated'
    ) {
      const router = useRouter();
      router.push('/signin');
      throw new Error(NOT_LOGIN);
    }
  }
}
