import { Options, useDialogStore } from '@/store/dialog';

class Dialog {
  async show(
    title: Nullable<string>,
    message: string,
    options?: Options,
  ): Promise<number> {
    const result = await new Promise<number>((resolve) => {
      useDialogStore().showDialog(title, message, resolve, options);
    });
    return result;
  }
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      dialog: new Dialog(),
    },
  };
});
