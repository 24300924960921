import { useSpinnerStore } from '@/store/spinner';

class Spinner {
  start() {
    useSpinnerStore().setVisible(true);
  }

  end() {
    useSpinnerStore().setVisible(false);
  }
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      spinner: new Spinner(),
    },
  };
});
