/**
 * 画面に表示するスピナーの表示・非表示を管理するStoreである。
 */

import { defineStore } from 'pinia';

export const useSpinnerStore = defineStore('spinner', {
  state: () => ({ visible: false }),

  actions: {
    /**
     * スピナーの表示・非表示を切り替える。
     * @param bool 表示・非表示を指定するbool値
     */
    setVisible(bool: boolean): void {
      this.visible = bool;
    },
  },
});
