export const INCORRECT_USERNAME_OR_PASSWORD = 'Incorrect username or password.';
export const NOT_LOGIN = 'Not Login';
export const UNSUPPORTED_MEDIA_RECORDER = 'unsupported Media Recorder';
export const UNSUPPORTED_MIME_TYPE = 'unsupported mime type';
export const PERMISSION_DENIED_MIC =
  'マイクへのアクセスが許可されていません。設定を確認して再度お試しください';

export function isError(err: unknown): err is Error {
  return err instanceof Error;
}

/**
 * 指定したエラーオブジェクトがcognitoログインエラーであるかどうかを判定する。
 * @param err エラーオブジェクト
 */
export function isAuthError(err: unknown): boolean {
  if (!isError(err)) {
    return false;
  }
  return (
    err.name === 'NotAuthorizedException' ||
    err.name === 'AuthError' ||
    err.message === NOT_LOGIN
  );
}

/**
 * 指定したエラーオブジェクトがマイク権限エラーであるかどうかを返す。
 * @param err エラーオブジェクト
 */
export function isMicPermissionError(err: unknown): boolean {
  if (!isError(err)) {
    return false;
  }
  return (
    err.message === 'Permission denied' ||
    err.name === 'NotAllowedError' ||
    err.name === 'PermissionDeniedError'
  );
}
