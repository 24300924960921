import { defineStore } from 'pinia';

export type Callback = (result: number) => void;
export type Options = {
  buttonLabels?: string[];
};

export const useDialogStore = defineStore('dialog', {
  state: () => ({
    visible: false,
    title: null as Nullable<string>,
    message: '',
    buttonLabels: ['OK'],
    callback: null as Nullable<Callback>,
  }),
  actions: {
    showDialog(
      title: Nullable<string>,
      message: string,
      cb: Callback,
      options?: Options,
    ): void {
      console.log('showDialog', title, message, options);
      this.visible = true;
      this.title = title;
      this.message = message;
      this.buttonLabels = options?.buttonLabels || ['OK'];
      this.callback = cb;
    },
    hideDialog(result: number): void {
      console.log('hideDialog', result);
      this.visible = false;
      this.callback?.(result);
    },
    forceHideDialog(): void {
      this.visible = false;
    },
  },
});
