import { Amplify } from 'aws-amplify';

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();
  Amplify.configure({
    Auth: {
      // See: https://aws-amplify.github.io/docs/js/authentication
      region: 'ap-northeast-1',
      userPoolId: runtimeConfig.public.USER_POOL_ID,
      userPoolWebClientId: runtimeConfig.public.CLIENT_ID,
      storage: window.sessionStorage,
    },
  });
});
