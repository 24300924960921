/**
 * ユーザーデータに関するAPI通信をまとめたStoreである。
 */

import { defineStore } from 'pinia';
import api from '@/utils/api';

export const useUserData = defineStore('userdata', {
  state: () => ({
    data: {} as Userdata,
  }),
  getters: {
    /**
     * 録音レベルの自動調整を有効にしているかどうかを取得する。
     */
    getSettingsAutoGainControl(state): boolean {
      const value = state.data.RecordingSetting?.AutoGainControl;
      if (value === undefined) {
        return false;
      }
      return value;
    },
    /**
     * エコーキャンセルを有効にしているかどうかを取得する。
     */
    getSettingsEchoCancellation(state): boolean {
      const value = state.data.RecordingSetting?.EchoCancellation;
      if (value === undefined) {
        return true;
      }
      return value;
    },
    /**
     * ノイズ抑制機能を有効にしているかどうかを取得する。
     */
    getSettingNoiseSuppression(state): boolean {
      const value = state.data.RecordingSetting?.NoiseSuppression;
      if (value === undefined) {
        return true;
      }
      return value;
    },
  },
  actions: {
    /**
     * POST /userdataを実行してユーザーデータを更新する
     * @param param 更新データ
     * @param param.section 更新データのセクション
     * @param param0.name 更新データのキー名
     * @param param0.value 更新データの値
     */
    async updateUserData({
      section,
      name,
      value,
    }: {
      section: string;
      name: string;
      value: string | boolean;
    }): Promise<void> {
      console.log('updateUserData', { section, name, value });
      const res = await api('/userdata', {
        method: 'POST',
        body: {
          section,
          name,
          value,
        },
      });

      this.data = res as Userdata;
    },
  },
});
